import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import io from 'socket.io-client';
// import loadable from '@loadable/component';
// import { Chart as ChartJS } from 'chart.js/auto';
import { getCurrentcolor } from '../redux/reducers/color.reducer';
import { getCurrentBranding } from '../redux/reducers/branding.reducer';
import { conversationsActions } from '../redux/actions/conversations.action';
import { setGlobalColors, changeFaviconAndDocumentTitle, isWebView, isIOS } from '../Utilities';
import { Loader } from '../Components/Common/Loader/Loading';
import './App.scss';
import { history, Routes } from '../Routing';
import { dashboardActions } from '../redux/actions/dashboard.action';
import { getCurrentLoadingStatus, getStatusOfSpinner } from '../redux/reducers/loading.reducer';
import withClearCache from './BustCache';
import { server } from '../Utilities/Remote';
// import BottomNavigation from '../Components/Common/BottomNavigation/BottomNavigation';
// import Sidebar from '../Version2/Components/Sidebar/Sidebar';

// const io = loadable.lib(() => import('socket.io-client'));

function MainApp(props) {
  const {
    color,
    currentbranding,
    isLoading,
    setSocket,
    isSpinner,
    setScrolledHeightOfDocumentToStore,
  } = props;

  useEffect(() => {
    const socket = io(server, {
      transports: ['websocket', 'polling'],
      autoConnect: true,
    });

    console.log(socket);
    socket.on('connect', () => {
      console.log(socket.id, 'connect');
    });

    socket.on('disconnect', () => {
      // console.log(socket.id, 'disconnected');
      socket.connect();
    });

    setSocket({ socket });
    return () => socket.emit('disconnect');
  }, []);

  useEffect(() => {
    function detectDevTools() {
      const threshold = 100; // Adjust based on docked tools' height

      const detect = () => {
        if (
          window.outerHeight - window.innerHeight > threshold ||
          window.outerWidth - window.innerWidth > threshold
        ) {
          window.location.href = 'about:blank';
        } else {
          requestAnimationFrame(detect); // Continue detecting
        }
      };
      detect();
    }
    if (!isIOS) detectDevTools();

    const handleContextMenu = (e) => {
      e.preventDefault(); // Prevent right-click menu
      // eslint-disable
    };

    document.addEventListener('contextmenu', handleContextMenu);

    const handleKeyDown = (e) => {
      // Disable F12, Ctrl+Shift+I, Ctrl+Shift+J, Ctrl+U (view source)
      if (
        e.keyCode === 123 ||
        (e.ctrlKey && e.shiftKey && e.keyCode === 73) ||
        (e.ctrlKey && e.shiftKey && e.keyCode === 74) ||
        (e.ctrlKey && e.keyCode === 85)
      ) {
        e.preventDefault(); // Disable the default action
        // eslint-disable
      }
    };

    const handleDevToolsChange = () => {
      alert('opened');
    };

    document.addEventListener('keydown', handleKeyDown);
    window.addEventListener('devtoolsopened', handleDevToolsChange);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  useEffect(() => {
    if (Object.keys(color.color) !== 0) {
      const { primary, light, lighter, superLight } = color.color;
      setGlobalColors(primary, light, lighter, superLight);
    }

    if (Object.keys(currentbranding.branding) !== 0) {
      const { client_icon: clientIcon, client_title: clientTitle } = currentbranding.branding;
      changeFaviconAndDocumentTitle(clientIcon, clientTitle);
    }
  }, [color, currentbranding, isSpinner]);

  return (
    <Container fluid className='p-0 m-0 rootContainer mx-auto'>
      {isLoading && <Loader isSpinner={isSpinner} />}
      <ConnectedRouter history={history}>
        <Routes />
      </ConnectedRouter>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSocket: (socket) => {
      dispatch(conversationsActions.setSocket(socket));
    },
    setScrolledHeightOfDocumentToStore: (payload) => {
      dispatch(dashboardActions.setScrolledHeightOfDocumentToStore(payload));
    },
  };
};

const mapStateToProps = (state) => ({
  color: getCurrentcolor(state),
  currentbranding: getCurrentBranding(state),
  isLoading: getCurrentLoadingStatus(state),
  isSpinner: getStatusOfSpinner(state),
});

const BustCache = withClearCache(connect(mapStateToProps, mapDispatchToProps)(MainApp));

function App() {
  return <BustCache />;
}

export default App;

MainApp.propTypes = {
  color: PropTypes.shape({
    color: PropTypes.shape({
      primary: PropTypes.string,
      light: PropTypes.string,
      lighter: PropTypes.string,
      superLight: PropTypes.string,
    }),
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  currentbranding: PropTypes.shape({
    branding: PropTypes.instanceOf(Object).isRequired,
  }).isRequired,
  isSpinner: PropTypes.bool.isRequired,
  setSocket: PropTypes.func.isRequired,
  setScrolledHeightOfDocumentToStore: PropTypes.func.isRequired,
};
